const TextBoxData01 = [
    {
        number: "765+",
        title: "Effective strategy",
        description: "Lorem ipsum dolor amet consectetur adipiscing eiusmod tempor."
    },
    {
        number: "1250+",
        title: "Customer satisfaction",
        description: "Lorem ipsum dolor amet consectetur adipiscing eiusmod tempor."
    },

    {
        number: "260+",
        title: "Consulting strategies",
        description: "Lorem ipsum dolor amet consectetur adipiscing eiusmod tempor."
    }

]

const TextBoxData02 = [
    {
        number: "01",
        title: "Accurate methodologies",
        description: "Lorem ipsum is simply dummy text of the printing and typesetting industry lorem ipsum has been."
    },
    {
        number: "02",
        title: "Expertise comprehension",
        description: "Lorem ipsum is simply dummy text of the printing and typesetting industry lorem ipsum has been."
    },

    {
        number: "03",
        title: "Discover explore course",
        description: "Lorem ipsum is simply dummy text of the printing and typesetting industry lorem ipsum has been."
    }

]

export { TextBoxData01, TextBoxData02 }