import React from 'react'
import '../Assets/css/Preloader.scss'
function Preloader() {
  return (
   <>

<div class="content-2">
   <div class="loading-2">
<p className='p-tag'>loading</p>
      <span className='span-2'></span>
   </div>
</div>



</>
  )
}

export default Preloader